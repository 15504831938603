import axios from '@/libs/axios'

export function random(min, max) {
  return Math.floor(Math.random() * (max - min + 1) + min)
}

/**
 * Randomize array element order in-place.
 * Using Durstenfeld shuffle algorithm.
 */
export const shuffleArray = array => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1))
    const temp = array[i]
    array[i] = array[j]
    array[j] = temp
  }
  return array
}

export const truncate = (str, n) => (str ? str.length > n ? `${str.substring(0, n - 1)}...` : str : '-')

export const getUrlOfFile = (file, media) => {
  const urlImg = media ? 'image-default.png' : 'avatars/default.jpeg'
  try {
    return URL.createObjectURL(file)
  } catch (error) {
    return file || `/img/${urlImg}`
  }
}

export const uploadFile = async file => {
  if (file) {
    const form = new FormData()
    form.append('file', file)
    const response = await axios.post('misc/upload', form)
    if (response.status === 200) {
      return response.data.data.file
    }
  }
  return ''
}

export const getFile = event => {
  const fileSizeLimit = 1024 * 1024 * 2 // limit 2 MB
  if (event.target.files[0].size < fileSizeLimit) {
    return event.target.files[0]
  }
  return this.$showToast('Failed', 'Max file size is 2 MB', 'danger')
}

export const exportExcel = (title, url, additional_params = {}) => {
  axios.get(url, {
    responseType: 'blob',
    params: Object.assign(additional_params),
  }).then(response => {
    const blob = new Blob(
      [response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' },
    )
    const aEle = document.createElement('a') // Create a label
    const href = window.URL.createObjectURL(blob) // Create downloaded link
    aEle.href = href
    aEle.download = title // File name after download
    document.body.appendChild(aEle)
    aEle.click() // Click to download
    document.body.removeChild(aEle) // Download complete remove element
    window.URL.revokeObjectURL(href) // Release blob object
  })
}
export const exportPDF = (title, url, additional_params = {}) => {
  axios.get(url, {
    responseType: 'blob',
    params: Object.assign(additional_params),
  }).then(response => {
    const blob = new Blob(
      [response.data], { type: 'application/pdf' },
    )
    const aEle = document.createElement('a') // Create a label
    const href = window.URL.createObjectURL(blob) // Create downloaded link
    aEle.href = href
    aEle.download = title // File name after download
    document.body.appendChild(aEle)
    aEle.click() // Click to download
    document.body.removeChild(aEle) // Download complete remove element
    window.URL.revokeObjectURL(href) // Release blob object
  })
}

export const strSplit = (str, splitFrom) => str.split(splitFrom).join(' ')

// export const getColorStatus = status => {
//   if (typeof status === 'number') {
//     let result = 'success'
//     switch (status) {
//       case 1:
//         result = 'warning'
//         break
//       case 2:
//       case 3:
//       case 4:
//       case 5:
//       case 6:
//       case 7:
//         result = 'success'
//         break
//       case 8:
//         result = 'danger'
//         break
//     }
//     return result
//   }
//   return status ? 'success' : 'danger'
// }

export const checkValidationNumber = event => {
  if (event.target.value.length === 0 && event.which === 48) {
    event.preventDefault()
  } else if ((event.which < 48 || event.which > 57) && event.which !== 13) {
    event.preventDefault()
  }
}

export const str_slug = string => {
  let slug = ''
  // Change to lower case
  const titleLower = string.toLowerCase()
  // Letter "e"
  slug = titleLower.replace(/e|é|è|ẽ|ẻ|ẹ|ê|ế|ề|ễ|ể|ệ/gi, 'e')
  // Letter "a"
  slug = slug.replace(/a|á|à|ã|ả|ạ|ă|ắ|ằ|ẵ|ẳ|ặ|â|ấ|ầ|ẫ|ẩ|ậ/gi, 'a')
  // Letter "o"
  slug = slug.replace(/o|ó|ò|õ|ỏ|ọ|ô|ố|ồ|ỗ|ổ|ộ|ơ|ớ|ờ|ỡ|ở|ợ/gi, 'o')
  // Letter "u"
  slug = slug.replace(/u|ú|ù|ũ|ủ|ụ|ư|ứ|ừ|ữ|ử|ự/gi, 'u')
  // Letter "d"
  slug = slug.replace(/đ/gi, 'd')
  // Trim the last whitespace
  slug = slug.replace(/\s*$/g, '')
  // Change whitespace to "-"
  slug = slug.replace(/\s+/g, '-')

  return slug
}
export const addToObject = (obj, key, value, index) => {
  if (!value) value = ''
  // Create a temp object and index variable
  const temp = {}
  let i = 0

  // Loop through the original object
  for (const prop in obj) {
    if (obj.hasOwnProperty(prop)) {
      // If the indexes match, add the new item
      if (i === index && key && value) {
        temp[key] = value
      }

      // Add the current item in the loop to the temp obj
      temp[prop] = obj[prop]

      // Increase the count
      i++
    }
  }

  // If no index, add to the end
  if (!index && key && value) {
    temp[key] = value
  }

  return temp
}
